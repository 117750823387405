<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card>
          <v-card-title class="title"> Settings </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs6 sm6>
        <v-card outlined>
          <v-card-title>
            <p class="subheading font-weight-bold">Account Settings</p>
          </v-card-title>
          <hr />
          <v-card-text>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-checkbox
                color="error"
                :value="item.text"
                :key="index"
                :label="item.text"
                v-model="opening_type"
              >
              </v-checkbox>
            </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-btn block large color="info outlined" @click="updateOpening()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    opening_type: '',
    items: [
      { value: 'fifo', text: 'First In First Out (FIFO)' },
      { value: 'lifo', text: 'Last In First Out (LIFO)' },
      { value: 'average', text: 'Weighted Average (WA)' },
    ],
  }),
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.$rest.get(`api/account-setting`).then(({ data }) => {
        let opening = '';
        Object.keys(data.data).forEach(function (key) {
          opening = data.data[key].setting.opening_type;
        });
        this.opening_type = opening;
      });
    },
    updateOpening() {
      this.$rest
        .post(`api/account-setting`, { params: this.opening_type })
        .then((res) => {
          this.$events.fire('notification', {
            message: res.data.data,
            status: 'success',
          });
        })
        .catch((err) => {
          this.$events.fire('notification', {
            message: err.response.message,
            status: 'error',
          });
        })
        .finally(() => {
          this.get();
        });
    },
  },
};
</script>

<style scoped></style>
